$(function() {
  // 以下為Swiper設定
 
   // 共用Auto 
   var auplayFun = {
     delay: 3500,
     //stopOnLastSlide: false,
     disableOnInteraction: false,
   }
 
   // 首頁Banner
   if($('.swiper-bannerIn').length > 0) {
     var swiper_bannerIn = new Swiper(".swiper-bannerIn .swiper-container", {
      // direction: "horizontal",
      loop: true,
      centeredSlides : true,
      slidesPerView:'auto',
      navigation: {
        nextEl: '.swiper-bannerIn .swiper-button-next',
        prevEl: '.swiper-bannerIn .swiper-button-prev',
      },
      grabCursor: true,
      speed: 1000,
      pagination: '.swiper-bannerIn .swiper-pagination',
      paginationClickable: true,
      // parallax: true,
      // effect: "slide",
      reverseDirection: true,

      // autoplay: {
      //   reverseDirection: true,
      // },
      autoplay: auplayFun,
      // mousewheelControl: 1,
     });
   }
 
   // 首頁產品分類
   var gallerSwiper_pro = new Swiper('.page-gallerSwiper-pro .swiper-container', {
     // autoplay: auplayFun,
     slidesPerView: 3,
     grabCursor : true,
     slideToClickedSlide: true,
     spaceBetween: 50,
     // centeredSlides: true,
     // centeredSlidesBounds: true,
     loop: true,
     autoHeight: true, //高度随内容变化
     
     pagination: {
       el: '.page-gallerSwiper-pro .swiper-pagination',
       type: 'fraction',
       clickable: true,
 
       renderFraction: function (currentClass, totalClass) {
         return '<span class="' + currentClass + '"></span>' + '<span class="swiper-pagination-empty"></span>' + '<span class="' + totalClass + '"></span>';
       },
 
       formatFractionCurrent: function (number) {
         return '0' + number;
       },
 
       formatFractionTotal: function (number) {
         return '0' + number;
       }
     },
 
     navigation: {
       nextEl: ".page-gallerSwiper-pro .swiper-button-next",
       prevEl: ".page-gallerSwiper-pro .swiper-button-prev",
     },
 
     breakpoints: { 
       320: {  //当屏幕宽度大于等于320
         slidesPerView: 1,
         spaceBetween: 0,
       },
       
       768: {  //当屏幕宽度大于等于768
         slidesPerView: 3,
         slidesPerGroup: 1,
         spaceBetween: 0,
       },
       
       1280: {  //当屏幕宽度大于等于768
         slidesPerView: 3,
         spaceBetween: 0,
       }
     }
   });
 
   // 產品詳細
   var Prod_mySwiper = new Swiper(".Prod-photo-small .Prod_mySwiper", {
     // autoplay: auplayFun,
     autoplay:false,
     freeMode: true,
     grabCursor : true,
     breakpoints: { 
       320: {  //当屏幕宽度大于等于320
         slidesPerView: 3,
         spaceBetween: 5,
       },
       768: {  //当屏幕宽度大于等于768
         slidesPerView: 3,
         spaceBetween: 10,
       },
       1280: {  //当屏幕宽度大于等于1280
         slidesPerView: 4,
         spaceBetween: 10,
       },
       1600: {  //当屏幕宽度大于等于1600
         slidesPerView: 5,
         spaceBetween: 15,
       }
     }
   });
 
   var Prod_mySwiper2 = new Swiper(".Prod-photo .Prod_mySwiper2", {
     // autoplay: auplayFun,
     autoplay:false,
     speed: 1000,
     spaceBetween: 10,
     navigation: {
       nextEl: ".Prod-photo .swiper-button-next",
       prevEl: ".Prod-photo .swiper-button-prev",
     },
     thumbs: {
       swiper: Prod_mySwiper,
     },
 
     breakpoints: { 
       320: {  //当屏幕宽度大于等于320
         spaceBetween: 0,
       },
       760: {  //当屏幕宽度大于等于760
         spaceBetween: 10,
       }
     }
   });
 
   // 產品詳細End
   
 
   // 以下為Gotop
   $(".footer-info-backTop, .float-link .link-btn.mobile-top").on('click',function () {
     $("html,body").animate({ "scrollTop": "0" })
   });
 
   $(window).on("load resize scroll", function (e) {
     var scroll = $(this).scrollTop();
     var wdh = $(window).height();
     if (scroll > 50) {
         $(".float-link").addClass("active");
     } else {
         $(".float-link").removeClass("active");
     }
   });
 
   // 產品側邊選單(電腦)
   var $slidemenu = $(".link-wrap ul > li > a.first_menu_link");
   $slidemenu.on('click', function () {
     // 點擊當前項目才打開
     var serToggle = $(this).closest('li').find(".first_menu");
     $(".first_menu").not(serToggle).removeClass('open');
     $slidemenu.not($(this)).removeClass('active');
 
     if ($(this).hasClass('active')) {
       $(this).removeClass('active');
       serToggle.removeClass('open');
     } else {
       $(this).addClass('active');
       serToggle.addClass('open');
     }
 
     e.stopPropagation();
   });
 
   // 產品側邊選單-下一層
   var $slidemenuSecond = $(".first_menu_li > a");
   $slidemenuSecond.on('click',function(){
     // 點擊當前項目才打開
     var serToggleSecond = $(this).closest('li').find(".second_menu");
     $(".second_menu").not(serToggleSecond).removeClass('active');
     $slidemenuSecond.not($(this)).removeClass('active');
 
     if($(this).hasClass('active')){
       $(this).removeClass('active');
       serToggleSecond.removeClass('active');
     }else{
       $(this).addClass('active');
       serToggleSecond.addClass('active');
     }
   });
 
   // 
   var $navbarmenu = $(".navbarMobile-toggle > li .nav-mobileBtn");
   $navbarmenu.on('click', function (e) {
     let curNext = $(this).next();
     $('.navbar_first_menu').not(curNext).removeClass('active');
     $(this).next().toggleClass('active');
 
     e.stopPropagation();
   });
    
   // BS navbar-collapse show / hidden
   $('.navbar-toggler').on('click',function(){
     $('.navbar-overlay, .navbar-toggler.fixed').addClass('active')
   })
 
   $('#js-navbarToggle-fixed').on('click', function () {
     $('#navbar-content').removeClass('show');
     $('.navbar-overlay, .navbar-toggler.fixed').removeClass('active')
   });
 
   // 文字點點點
   checkEllipsis('.index-card-text',50);
   
   if ($('#my-scrollbar').length > 0) {
     var Scrollbar = window.Scrollbar;
     Scrollbar.init(document.querySelector('#my-scrollbar'), {});
   }
 
   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
     return new bootstrap.Tooltip(tooltipTriggerEl)
   })
   
 });
 
 function resize() {
   var winHeight = $(window).height();
   $(".modal-autoheight .modal-body").css({
     width: "auto",
     height: (winHeight - 200) + "px"
   });
 }
 
 // 取得頁面寬度
 function viewport() {
   var e = window,
       a = 'inner';
   if (!('innerWidth' in window)) {
       a = 'client';
       e = document.documentElement || document.body;
   }
   return { width: e[a + 'Width'], height: e[a + 'Height'] };
 }
 
 // 文字點點點
 function checkEllipsis(name,len){
   $(name).each(function(i){
     if($(this).text().length>len){
         $(this).attr("title",$(this).text());
         let text=$(this).text().substring(0,len-1)+"...";
         $(this).text(text);
     }
   });
 }
 